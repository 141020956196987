
const Servicios = () => {
	return(
		<section className='flex justify-center items-center py-16 '>
			<div>

				{/*<div className='mb-[3em]'>
					<h1 className='font-roboto font-bold text-[1.8rem]'>Brindamos soluciones al transporte <br/> generando seguridad y confianza en nuestros clientes.</h1>
				</div>*/}

				<div className='lg:flex justify-between'>

					<div className='lg:w-[50%]'>
						<p className='font-playfair font-medium text-3xl leading-normal mb-5'>
							Por qué deberias elegir nuestros <br/> 
							servicios,  aquí están las ventajas que ofrecemos
						</p>
						<p className='font-merriweather text-sm text-justify leading-relaxed text-gray'>
							Nos enorgullecemos de ofrecer los mejores servicios de envío y transporte disponibles en cualquier parte del mundo.
							utilizando software para rastrear el procesamiento y las comunicaciones, junto con nuestro personal capacitado con décadas de experiencia.
						</p>

						<div className='flex mt-12'>
							<a href='#cotizacion' className='bg-blue font-merriweather text-[.80rem] text-white p-3 rounded'>Obtenga una Cotización</a>
						</div>
					</div>

					<div className='mt-[5em] lg:mt-0 lg:w-[50%] lg:flex lg:flex-col lg:items-end'>

						<div className='lg:w-[50%] mb-10'>

							<div>
								<p className='font-playfair font-medium text-xl'>Carga Segura</p>
								<p className='font-merriweather text-sm text-justify leading-relaxed  mt-3 text-gray'>Contamos con personal monitoreando el estado de tu carga, para garantizar la seguridad de tu mercancia.</p>
							</div>

						</div>	

						<div className='lg:w-[50%] mb-10'>

							<div>
								<p className='font-playfair font-medium text-xl'>Envío Rápido</p>
								<p className='font-merriweather text-sm text-justify leading-relaxed  mt-3 text-gray'>Nuestras certificaciones nos permiten hacer uso de carriles fast y express, para un cruze fronterizo mas rápido</p>
							</div>

						</div>

						<div className='lg:w-[50%]'>

							<div>
								<p className='font-playfair font-medium text-xl'>Cobertura</p>
								<p className='font-merriweather text-sm text-justify leading-relaxed mt-3 text-gray'>Nuestra area de cobertura directa incluye todo Mexico. Nuestra area de cobertura extendida cubre parcialmente el territorio de Estados Unidos.</p>
							</div>

						</div>

					</div>
				</div>

			</div>
		</section>
	)
}

export default Servicios;