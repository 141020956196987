import './App.css';
import './index.css'

import Navbar from './components/navbar';
import Main from './components/main';
import Header from './components/header';
import Informacion from './components/informacion';
import Servicios from './components/servicios';
import Nosotros from './components/nosotros';
import Maps from './components/maps';

import Cotizacion from './components/cotizacion';
import Footer from './components/footer';
import Video from './components/video';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <Router>

      <div className='w-full overflow-hidden'>

        <div className=' flex justify-center items-center border-b'>
          <div className='xl:max-w-[1280px] w-full px-10 py-5 lg:px-20 md:px-16'>
            <Navbar />
          </div>
        </div>

        <div className='bg-white flex justify-center items-center bg-main bg-cover bg-no-repeat bg-center'>
          <div className='xl:max-w-[1280px] w-full px-10 py-40 lg:px-20 md:px-16'>
            <Main />
          </div>
        </div>

        <div id='soluciones' className='flex justify-center items-center bg-services bg-no-repeat bg-top'>
          <div className='xl:max-w-[1280px] w-full px-10 py-20 lg:px-20 md:px-16'>
            <Servicios />
          </div>
        </div>

        <div id="cotizacion" className='flex justify-center items-center bg-quote bg-cover bg-no-repeat bg-center'>
          <div className='xl:max-w-[1280px] w-full px-10 py-20 lg:px-20 md:px-16 '>
            <Cotizacion />
          </div>
        </div>

        <div id='nosotros' className='flex justify-center items-center bg-services bg-no-repeat bg-bottom'>
          <div className='xl:max-w-[1280px] w-full px-10 py-20 lg:px-20 md:px-16'>
            <Nosotros />
          </div>
        </div>

        <div className='flex justify-center items-center bg-gray-strong'>
          <div className='xl:max-w-[1280px] w-full px-10 py-20 lg:px-20 md:px-16'>
            <Footer/>
          </div>
        </div>

      </div>
    </Router>
  );
}

// #0049FF

export default App;
