const Main = () => {
	return(
		<section id="home" className="w-full">
			<div className="lg:w-[50%]">
				<p className="font-playfair font-medium text-3xl text-white leading-normal mb-5">Somos tu mejor opción en transporte refrigerado. Brindamos soluciones al transporte generando seguridad y confianza en nuestros clientes.</p>
			</div>
		</section>
	)
}

export default Main