import mision from '../assets/mision.png';
import vision from '../assets/vision.png';
import Video from './video';

const Nosotros = () => {
	return(
		<section className='flex justify-center items-center py-16'>
			<div className="lg:flex">
				
				<div className='lg:w-[50%] lg:mr-[5em]'>

					<div>
						<p className='font-playfair font-medium text-3xl leading-normal mb-5'>Somos una gran empresa mexicana con una larga trayectoria en el transporte refrigerado.</p>
					</div>

					<div>
						<p className='font-merriweather text-sm text-justify leading-relaxed text-gray'>Nos caracterizamos
							por ser la empresa de mayor crecimiento en el transporte de carga, esto gracias a que estamos conformados por un
							equipo multidisciplinario de profesionales y operadores con amplia experiencia en el ramo.
						</p>
					</div>

				</div>

				<div className='lg:w-[50%]'>
					<Video />
				</div>

				{/*<div className='lg:w-[50%] flex flex-col  items-end'>

					<div className='lg:w-[70%] mb-10'>

						<p className='font-playfair font-medium text-xl'>Nuestra Misión</p>
						<p className='font-merriweather text-sm text-justify leading-relaxed  mt-3 text-gray'>
							Somos una empresa dedicada al transporte de carga, buscamos satisfacer plenamente a nuestros clientes con seguridad y cumplimiento, para esto contamos con el mejor equipo técnico y los valores éticos, morales y la capacitación constante del capital humano el activo mas valioso que poseemos.
						</p>

					</div>

					<div className='lg:w-[70%] mb-10'>

						<p className='font-playfair font-medium text-xl'>Nuestra Visión</p>
						<p className='font-merriweather text-sm text-justify leading-relaxed mt-3 text-gray'>Ser una empresa de transporte de carga reconocida a nivel nacional como internacional cubriendo las
							principales rutas de nuestro país, así como brindar este servicio a nivel internacional, satisfaciendo las exigencias y
							expectativas de nuestros clientes.
						</p>

					</div>

					<Video/>

				</div>*/}

			</div>
		</section>
	)
}

export default Nosotros;