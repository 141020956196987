import ReactPlayer from "react-player";
import videocorporativo from '../assets/video-corporativo.mp4'
import backgroundvideo from '../assets/background-video.jpg'

const Video = () => {
	return(
				<ReactPlayer
					url={videocorporativo}
					light={backgroundvideo} 
					playing={true} 
					controls={true}
					width={"100%"}
				/>
	)
}

export default Video;