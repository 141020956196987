const Cotizacion = () => {
	return(
		<div className="lg:flex lg:flex-row-reverse lg:justify-between lg:items-center lg:pt-10">

			<div className="mt-10 lg:w-[50%] lg:flex lg:flex-col lg:justify-end lg:items-end text-white">
				<div>
					<p className="font-playfair font-medium text-3xl leading-normal mb-20">Sencillo y fácil con solo tres pasos.</p>
				</div>

				<div className="flex flex-col">

					<div className="mb-10">
						<p className="font-playfair font-medium text-xl"><span className="text-blue mr-3">01</span> Obtenga un presupuesto para planificar</p>
						{/*<p className="font-merriweather text-sm leading-relaxed mt-3 text-gray">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>*/}
					</div>

					<div className="mb-10">
						<p className="font-playfair font-medium text-xl"><span className="text-blue mr-3">02</span> Reciba apoyo continuo</p>
						{/*<p className="font-merriweather text-sm leading-relaxed mt-3 text-gray">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>*/}
					</div>

					<div className="mb-10">
						<p className="font-playfair font-medium text-xl"><span className="text-blue mr-3">03</span> Transporta con nosotros</p>
						{/*<p className="font-merriweather text-sm leading-relaxed mt-3 text-gray">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>*/}
					</div>

				</div>

			</div>

			<div className="w-full lg:w-[50%]">
				<form action="https://formsubmit.co/sistemas@trr.com.mx " method="POST" class="w-full lg:max-w-[40rem] border p-10 bg-white">

					<div class="flex flex-wrap -mx-3 mb-6">

						<div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label class="block uppercase tracking-wide text-gray-700 text-[0.70rem] font-heebo font-bold mb-2" for="grid-city">
								NOMBRE COMPLETO:
							</label>
							<input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200  rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" name="Nombre" placeholder="" required/>
						</div>

						<div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label class="block uppercase tracking-wide text-gray-700 text-[0.70rem] font-heebo font-bold mb-2" for="grid-city">
								TELEFONO:
							</label>
							<input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" name="Telefono" placeholder="" required/>
						</div>

					</div>

					<div className="flex flex-wrap -mx-3 mb-[5em]">

						<div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label class="block uppercase tracking-wide text-gray-700 text-[0.70rem] font-heebo font-bold mb-2" for="grid-city">
								CORREO ELECTRONICO:
							</label>
							<input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="email" name="Correo" placeholder="" required/>
						</div>

					</div>


					<div className="flex flex-wrap -mx-3 mb-6">

						<div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label class="block uppercase tracking-wide text-gray-700 text-[0.70rem] font-heebo font-bold mb-2" for="grid-city">
								TIPO DE PRODUCTO:
							</label>
							<input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" name="Tipo de Producto" placeholder="" required/>
						</div>



						<div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label class="block uppercase tracking-wide text-gray-700 text-[0.70rem] font-heebo font-bold mb-2" for="grid-city">
								CANTIDAD:
							</label>
							<input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" name="Cantidad" placeholder="" required/>
						</div>


					</div>

					<div class="flex flex-wrap -mx-3 mb-2">

						<div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label class="block uppercase tracking-wide text-gray-700 text-[0.70rem] font-heebo font-bold mb-2" for="grid-city">
								ORIGEN:
							</label>
							<input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" name="Origen" placeholder="" required/>
						</div>

						<div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label class="block uppercase tracking-wide text-gray-700 text-[0.70rem] font-heebo font-bold mb-2" for="grid-city">
								DESTINO:
							</label>
							<input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" name="Destino" placeholder="" required/>
						</div>


					</div>

					<div className="mt-[4em] text-center">
						<button type="submit" className="w-full bg-blue text-[0.70rem] font-bold text-white p-2.5 rounded border-0">ENVIAR</button>
					</div>

				</form>
			</div>

		</div>
	)
}

export default Cotizacion;