export const navLinks = [
	{
		id: 1,
		title: "INICIO",
		link: ""
	},
	{
		id: 2,
		title: "SOLUCIONES",
		link: "soluciones"
	},
	{
		id: 3,
		title: "NOSOTROS",
		link : "nosotros"
	},
	{
		id: 4,
		title: "BOLSA DE EMPLEO",
		link: "empleo"
	},
	{
		id: 5,
		title: "CONTACTO",
		link: "contacto"
	},
];