import facebook from '../assets/facebook.png';
import youtube from '../assets/youtube.png';

const Footer = () => {
	return(
		<section>


				
				<div className='flex justify-between'>
					<div className='flex flex-col'>
						<p className='font-playfair text-white text-[.90rem] mb-5'>Documentación</p>
						<a href="https://www.responsiblebusiness.org/media/docs/RBACodeofConduct7.0_Spanish.pdf" target="_blank" rel="noreferrer" className='font-merriweather text-white text-[.75rem] underline mb-2'>Código de Ética</a>
						<a href="https://docs.google.com/document/d/1skX-Lrpc1r7FoyRL-utPJEldVvsQ67NVuCZwPxTmp_0/edit?hl=es-419&forcehl=1" target="_blank" rel="noreferrer" className='font-merriweather text-white text-[.75rem] underline'>Política de Gestión</a>
					</div>

					<a href='https://www.facebook.com/transportesrefrigeradosrivas/' target="_blank"><img src={facebook} alt="" className='w-[20px] mr-5' /></a>
				</div>



			

		</section>
	)
}

export default Footer;