import { useState } from "react";
import { navLinks } from "../constants";


import menu from '../assets/menu.png';
import close from '../assets/close.png';
import Logo  from '../assets/Logo.png';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const Navbar = () => {

	const [toogle, setToogle] = useState(false);

	return(
		<nav class="flex items-center justify-between flex-wrap ">

			{/*<div class="flex items-center flex-shrink-0  mr-16">
				<span class="font-heebo font-bold text-xl tracking-tight">RIVAS TRUCK'S</span>
			</div>*/}

			<div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
				<div class="text-sm lg:flex-grow">
					<a href="#soluciones" class="block mt-4 lg:inline-block lg:mt-0 mr-10 font-heebo font-medium text-[0.80rem]">
						SOLUCIONES
					</a>
					<a href="#cotizacion" class="block mt-4 lg:inline-block lg:mt-0 mr-10 font-heebo font-medium text-[0.80rem]">
						COTIZACIÓN
					</a>
					<a href="#nosotros" class="block mt-4 lg:inline-block lg:mt-0 mr-10 font-heebo font-medium text-[0.80rem]">
						SOBRE NOSOTROS
					</a>
					<a href="https://www.responsiblebusiness.org/media/docs/RBACodeofConduct7.0_Spanish.pdf" target="_blank" class="block mt-4 lg:inline-block lg:mt-0 font-heebo font-medium text-[0.80rem]">
						CÓDIGO DE ÉTICA
					</a>
				</div>

				<div>
					<a href="#" class="font-heebo font-medium text-[0.80rem] text-gray-400 inline-block leading-none  mt-4 lg:mt-0">INICIO DE SESION</a>
				</div>
			</div>
		</nav>
	)
}

export default Navbar;